function round(num: number, pres: number) {
  return Math.round(num * pres) / pres;
}

export function symplifyNumber(a: number) {
  if (a >= 1_000) {
    if (a < 1_000_000) return `${round(a / 1_000, 10)}K`;
    if (a < 1_000_000_000) return `${round(a / 1_000_000, 10)}M`;
    if (a < 1_000_000_000_000) return `${round(a / 1_000_000_000, 10)}B`;
    return `${round(a / 1_000_000_000_000, 10)}T`;
  }
  return a
}

export function formatMoney(money: number | null) {
  if (money === null) money = 0
  return money.toLocaleString('en', {
    currency: 'USD',
    style: 'currency',
    ...(money < 10_000 ? { maximumSignificantDigits: 5 } : { maximumFractionDigits: 0 }),
  })
}
