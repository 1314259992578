import { useCoinDescriptions } from 'api/hooks/use-coin-descriptions';
import { Risk } from 'app/utils/getCoinData/getCoinData';
import styles from './styles.module.css';

interface Props {
  name: string
  currencyId: string
  internalDescription: string | undefined
  risk: Risk
}

function getDescription(risk: Risk) {
  const link = <a href="/" style={{ textDecoration: 'underline' }}>on the main page</a>
  switch (risk) {
    case 'Low': return (
      <div>
        <p>
          Based on our initial analysis, this token appears to have a
          low likelihood of being considered haram.
        </p>
        <p>
          We will provide a detailed analysis and supporting arguments at a later time.
          Additionally, we maintain a list of halal tokens {link}.
        </p>
      </div>
    )
    case 'Medium': return (
      <div>
        <p>
          Based on our initial analysis, this token appears to have a
          medium risk of being considered haram.
        </p>

        <p>
          We will provide a detailed analysis and supporting arguments at a later time.
          As a reminder, we maintain a list of halal tokens {link}.
        </p>
      </div>
    )
    case 'High': return (
      <div>
        <p>
          Based on our initial analysis, this token appears to have a
          high risk of being considered haram.
          We will provide a detailed analysis and supporting arguments at a later time.
        </p>

        <p>
          As a reminder, we maintain a list of halal tokens {link}.
        </p>
      </div>
    )
    default: return (
      <div>
        <p>
          We haven't checked <span id="coinTitle">the coin</span> yet,
          but we have a list of halal tokens {link}.
        </p>
        <p>
          Please feel free to share your thoughts about
          permissiveness of the coin in the comments.
        </p>
      </div>
    )
  }
}

function hasDescription(html: string | undefined): html is string {
  return typeof html === 'string' && !html.startsWith('<!doctype html><html')
}

const CoinDescription = ({ name, currencyId, internalDescription, risk }: Props) => {
  const coinDescriptions = useCoinDescriptions(currencyId)

  return (
    <div className={styles.descriptionContainer}>
      <h1 style={{ fontSize: '1.8rem' }}>Is {name} Halal?</h1>
      <div dangerouslySetInnerHTML={{ __html: hasDescription(coinDescriptions.data)
        ? coinDescriptions.data : '' }}
      />

      {coinDescriptions.isError || !hasDescription(coinDescriptions.data) ?
        <>
          <div style={{ whiteSpace: 'break-spaces' }}>
            {getDescription(risk)}
            {risk !== 'Unverified' ?
              'However, we want to remind you that it is important to exercise caution and'
              + 'conduct your own research when making investments.' : null}
          </div>

          {internalDescription ?
            <div>
              <h2>{name} in Details</h2>
              <div
                className={styles.internalDescription}
                dangerouslySetInnerHTML={{ __html: internalDescription }}
              />
            </div>
            : null}
        </>
        : null}
    </div>
  );
}

export default CoinDescription
