import image from './link.svg'
import styles from './styles.module.css';

interface Params {
  urls: string[] | { [url: string]: string[] };
  defaultLabel: string;
  alwaysShowDefaultLabel?: boolean;
  showAllWithoutRedirect?: boolean;
}

function button(url: string, text: string | JSX.Element) {
  return (
    <span className={styles.link}>
      <a href={url}>{text}
        <img src={image} alt="Link" />
      </a>
    </span>
  )
}

const Category = (params: Params) => {
  // homepage: [...] => [homepage, [homepage1, homepage2]]
  // OR
  // {github: [...], bitbucket: [...]} => [github, [repo1, repo2]]
  let urlEntries: [string, string[]][] = []
  let multipleUrls: boolean;
  if (Array.isArray(params.urls)) {
    urlEntries = [[params.defaultLabel, params.urls.filter(Boolean)]]
    multipleUrls = urlEntries[0][1].length > 1
  } else {
    urlEntries = Object.entries(params.urls)
    let length = 0
    for (const [, urls] of urlEntries) {
      length += urls.length
    }
    multipleUrls = length > 1
  }

  if (multipleUrls || params.showAllWithoutRedirect || !Array.isArray(params.urls)) {
    return (
      <div className={styles.root}>
        <span className={`${styles.button} ${styles.alwaysShow}`}>{params.defaultLabel}</span>
        <div className={styles.showOnHover}>
          <div className={styles.pointer} />
          <div className={styles.bubble}>
            <div>
              {urlEntries.map(([label, urls]) => (
                urls.map(url => (
                  <div key={url} className={styles.dropDownItem}>
                    {params.showAllWithoutRedirect
                      ? url :
                      <a href={url}>
                        {Array.isArray(params.urls) ? new URL(url).hostname : label}
                        <img src={image} alt="Link" />
                      </a>}
                  </div>))
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const [[name, [url]]] = urlEntries
  return (
    <div className={styles.root}>
      {button(url, params.alwaysShowDefaultLabel ? params.defaultLabel : name)}
    </div>
  )
}

export default Category
