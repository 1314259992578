import styles from './styles.module.css';

const Footer: React.FC = () => (
  <footer className={styles.footer}>
    <div>
      <a className={styles.link} target="_blank" rel="noreferrer" href="https://t.me/hclchat">Contact us</a>
      <a className={styles.link} target="_blank" rel="noreferrer" href="https://halalcoinlist.notion.site/Methodology-11a18306434f4ebab08935a3c5c47025">Methodology</a>
      <a className={styles.link} target="_blank" rel="noreferrer" href="https://halalcoinlist.notion.site/Contact-us-523146ff835c4d38b61c04f352dd42f0">About us</a>
    </div>
  </footer>
)

export default Footer
