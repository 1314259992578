import { useMemo, useState } from 'react'

type SortDirection = 'asc' | 'desc' | 'as is'
const nextSortDirection: Record<SortDirection, SortDirection> = {
  asc: 'desc',
  desc: 'as is',
  'as is': 'asc',
}
const onFirstClick: SortDirection = 'asc'

const { compare } = new Intl.Collator(undefined, { sensitivity: 'base', numeric: true })
const compareNumbers = (a: any, b: any) => a - b
const negateCompare = (cmp: typeof compareNumbers) => (a: any, b: any) => cmp(b, a)

export function useSortableData<T>(
  items: T[] | undefined,
  initialConfig: {
    key?: keyof T | null,
    sortDirection?: SortDirection
  } = {},
) {
  if (initialConfig.key === undefined) {
    initialConfig.key = null
  }
  if (initialConfig.sortDirection === undefined) {
    initialConfig.sortDirection = 'as is'
  }

  type RequiredConfig = Required<typeof initialConfig>
  const [config, setConfig] = useState<RequiredConfig>(initialConfig as RequiredConfig)
  const { key, sortDirection } = config

  items = useMemo(() => {
    if (!items || sortDirection === 'as is') return items
    if (key === null) {
      let sortedItems = items
      if (sortDirection === 'desc') {
        sortedItems = [...items].reverse()
      }
      return sortedItems
    }

    let cmp = items.length > 0 && typeof items[0][key] === 'number' ? compareNumbers : compare
    if (sortDirection === 'desc') cmp = negateCompare(cmp)

    return [...items].sort((a, b) => cmp(a[key] as any, b[key] as any))
  }, [items, key, sortDirection])

  const requestSort = (newKey: keyof T | null) => {
    const newNextSortDirection = key === newKey ? nextSortDirection[sortDirection] : onFirstClick
    setConfig({ key: newKey, sortDirection: newNextSortDirection })
  }

  return { items, requestSort, config }
}
