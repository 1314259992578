// https://api.coingecko.com/api/v3/coins/bitcoin?localization=false&tickers=false&market_data=false&community_data=false&developer_data=false&sparkline=false

import { processCoingecko } from 'api';
import { useQuery } from 'react-query';

interface CurrencyInfo {
  id: string;
  name: string;
  symbol: string;
  description: { en: string }
  market_cap_rank: number;
  categories: (string | null)[];
  image: {
    thumb: string;
    small: string;
    large: string;
  }
  links: {
    homepage: string[];
    blockchain_site: string[];
    facebook_username?: string;
    chat_url: string[];
    repos_url: {
      [repo: string]: string[];
    }
    subreddit_url: string;
    twitter_screen_name?: string;
  }
}

export function useCurrencyInfo(currencyId: string) {
  const params = {
    localization: false,
    tickers: false,
    market_data: false,
    community_data: false,
    developer_data: false,
    sparkline: false,
  }

  const resp = useQuery<CurrencyInfo>([`coins/${currencyId}`, params], processCoingecko, {
    onError(error) {
      console.error('error occured, please, check', error);
    },
  });
  return resp;
}
