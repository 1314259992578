import React from 'react';
import styles from './styles.module.css';

interface Props {
  children: React.ReactNode
}

const Grid: React.FC<Props> = ({ children }: Props) => (
  <section className={styles.parent}>{children}</section>
)
export default Grid;
