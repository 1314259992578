import { useSearch } from 'api/hooks/use-search'
import { useCallback, useState } from 'react'
import { Link } from 'react-router-dom';
import icon from './loupe.svg'
import styles from './styles.module.css';
import TextMarker from '../TextMarker/TextMarker';

// eslint-disable-next-line @typescript-eslint/ban-types
function throttle<T extends Function>(f: T, delay: number) {
  let lastTimeoutId = 0
  const wrapper = (...args: unknown[]) => {
    window.clearTimeout(lastTimeoutId)
    lastTimeoutId = window.setTimeout(f, delay, ...args)
  }
  return wrapper as unknown as T
}

const Search = () => {
  const [value, setValue] = useState('')
  const [debouncedValue, setDebouncedValue] = useState('')
  const querySearch = useSearch(debouncedValue)
  const [cachedData] = useState(querySearch.data?.coins ?? [])
  if (querySearch.data?.coins.length) {
    cachedData.length = 0
    cachedData.push(...querySearch.data.coins)
  }

  const changeWithDelay = useCallback(throttle((search: string) => {
    setDebouncedValue(search)
  }, 500), [])
  const valueChanged = useCallback((newValue: string) => {
    setValue(newValue)
    changeWithDelay(newValue)
  }, [changeWithDelay])

  // const coins = querySearch.data?.coins
  const coins = cachedData

  return (
    <label className={styles.root} htmlFor={styles.input}>
      <div className={styles.inputContainer}>
        <img src={icon} alt="Loupe" />
        <input id={styles.input} className={styles.input} type="search" onChange={e => valueChanged(e.target.value)} value={value} placeholder="Search" />
      </div>
      {value && debouncedValue && coins?.length ?
        <div className={styles.foundList}>
          {coins.map(c => (
            <div key={c.id}>
              <Link to={`/is-${c.id}-halal`} onClick={() => setDebouncedValue('')}>
                <img src={c.thumb} alt={c.name} />
                <TextMarker text={c.name} pattern={value} />
                <TextMarker text={c.symbol} pattern={value} className={styles.ticker} />
              </Link>
            </div>
          ))}
        </div>
        : null}
    </label>
  )
}

export default Search
