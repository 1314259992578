import { formatMoney, symplifyNumber } from 'app/utils/numbers';
import { drawChart } from 'components/CryptoTable/draw-on-canvas';
import { createRef, MutableRefObject, useEffect, useRef } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Coin, usePrices } from 'api/hooks/use-prices';
import commonStyles from 'components/common-styles.module.css';
import { getCoinData } from 'app/utils/getCoinData/getCoinData';
import { useSortableData } from 'api/hooks/use-sortable-data';
import DefaultCoins from './default-coins.json'
import styles from './styles.module.css';

const perPage = 100

// Keep previous some coins to show them in blur
// while usePrices is loading
let previousCoins: Coin[] = DefaultCoins

const CryptoTable: React.FC = () => {
  const [params] = useSearchParams({ page: '1' })
  const pageNumber = Number(params.get('page') ?? '1')
  const pricesQuery = usePrices({ pagination: { pageNumber, perPage } });
  const canvasRefs = useRef<MutableRefObject<HTMLCanvasElement>[]>([]);
  const { items: coins, requestSort, config } = useSortableData(pricesQuery.data)
  const rankOffset = (pageNumber - 1) * perPage + 1

  useEffect(() => {
    if (!coins) return
    previousCoins = coins

    const id = setTimeout(() => {
      for (let i = 0; i < coins.length; i++) {
        const canvas = canvasRefs.current[i].current;
        const prices = coins[i].sparkline_in_7d.price;
        drawChart(canvas, prices);
      }
    }, 400);

    return () => clearTimeout(id)
  }, [coins])

  if (coins && canvasRefs.current.length !== coins.length) {
    canvasRefs.current = coins.map((_, i) => canvasRefs.current[i] ?? createRef());
  }

  const getClassName = (key: keyof Coin | null) => {
    if (config.key === key) {
      if (config.sortDirection === 'asc') return ` ${styles.sortUp}`
      if (config.sortDirection === 'desc') return ` ${styles.sortDown}`
    }
    return ''
  }

  return (
    <div className={commonStyles.tableContainer}>
      <table className={commonStyles.table}>
        <thead>
          <tr>
            <th onClick={() => requestSort(null)} className={styles.rank + getClassName(null)}>
              #
            </th>
            <th onClick={() => requestSort('name')} className={getClassName('name')}>Name</th>
            <th onClick={() => requestSort('current_price')} className={getClassName('current_price')}>Price</th>
            <th className={styles.haramRiskCloser}>Haram Risk</th>
            <th onClick={() => requestSort('price_change_percentage_7d_in_currency')} className={getClassName('price_change_percentage_7d_in_currency')}>7d %</th>
            <th onClick={() => requestSort('market_cap')} className={getClassName('market_cap')}>Market Cap</th>
            <th onClick={() => requestSort('total_volume')} className={getClassName('total_volume')}>Volume(7d)</th>
            <th onClick={() => requestSort('circulating_supply')} className={getClassName('circulating_supply')}>Supply</th>
            <th>Price Chart</th>
            <th className={styles.haramRiskFarther}>Haram Risk</th>
          </tr>
        </thead>
        <tbody className={!coins ? styles.loading : ''}>
          {(coins ?? previousCoins).map((c, i) => {
            const coinData = getCoinData([c.id, c.name, c.symbol])
            const haramRisk = coinData?.risk ?? 'Unverified';
            const changeIn7Days = c.price_change_percentage_7d_in_currency ?? 0
            const rank = rankOffset + i
            const haramRiskColumn = (
              <div className={`${styles.haramRisk} ${commonStyles[haramRisk.toLocaleLowerCase()]}`}>{haramRisk}</div>
            )
            const coinHref = `/is-${c.id}-halal`

            return (
              <tr key={c.symbol}>
                <td className={styles.rank}>{rank}</td>
                <td style={{ textAlign: 'left' }}>
                  <Link className={styles.link} to={coinHref}>
                    <img className={styles.currencyImage} src={c.image} alt={`${c.name}`} loading="lazy" />
                    <div className={styles.linkRestText}>
                      {c.name}
                      <span>
                        <span className={styles.squeezedRank}>{rank}</span>
                        <span className={styles.ticker}> {c.symbol.toUpperCase()}</span>
                      </span>
                    </div>
                  </Link>
                </td>
                <td>{formatMoney(c.current_price)}</td>
                <td className={styles.haramRiskCloser}>{haramRiskColumn}</td>
                <td className={changeIn7Days > 0
                  ? commonStyles.increasingColor
                  : changeIn7Days < 0 ? commonStyles.decreasingColor : ''}
                >
                  {changeIn7Days.toFixed(2)}%
                </td>
                <td>{formatMoney(c.market_cap)}</td>
                <td>{formatMoney(c.total_volume)}</td>
                <td title={`Total supply: ${c.total_supply ? symplifyNumber(c.total_supply) : 'unknown'}`}>{symplifyNumber(c.circulating_supply ?? 0)}</td>
                <td style={{ padding: 0 }}>
                  <Link to={coinHref}>
                    <canvas ref={canvasRefs.current[i]} className={styles.sparkLine7DaysCanvas} />
                  </Link>
                </td>
                <td className={styles.haramRiskFarther}>
                  <Link to={coinHref}>{haramRiskColumn}</Link>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  );
}
export default CryptoTable;
