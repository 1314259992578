import 'normalize.css';
import 'inter-ui/inter.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Main from 'routes/Main';
import Cryptocurrency from 'routes/Cryptocurrency';
import Footer from 'components/Footer/Footer'
import NotFound from 'components/NotFound/NotFound';
import Header from './components/Header/Header';
import Grid from './components/Grid/Grid';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';

// eslint-disable-next-line
function App() {

  return (
    <Grid>
      <BrowserRouter>
        <Header />
        <main>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/is-:id-halal" element={<Cryptocurrency />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
        <Footer />
      </BrowserRouter>
    </Grid>
  );
}

export default App;
