import { processLocalPage } from 'api';
import { useQuery } from 'react-query';

export function useCoinDescriptions(id: string) {
  const resp = useQuery<string>([`/coin-descriptions/${id}.html`], processLocalPage, {
    onError(error) {
      console.error('error occured, please, check', error);
    },
    retry: false,
  });
  return resp;
}
