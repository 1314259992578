import { useAppSelector } from 'app/hooks'
import CryptoTable from 'components/CryptoTable/CryptoTable'
import Description from 'components/Description/Description'
import Pagination from 'components/Pagination/Pagination'
import { selectPages } from 'features/totalPages/totalPagesSlice'

const Main = () => {
  const totalPages = Math.max(100, useAppSelector(selectPages))
  const width = document.body.clientWidth
  /* eslint-disable @typescript-eslint/indent */
  const showOnEachSide =
    width >= 440 ? 5 :
    width >= 380 ? 4 : 3
  /* eslint-enable */

  return (
    <>
      <Description />
      <CryptoTable />
      <Pagination showOnEachSide={showOnEachSide} maxPageSize={totalPages} />
    </>
  )
}

export default Main
