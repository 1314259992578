import { useAppDispatch } from 'app/hooks';
import { setTotalPages } from 'features/totalPages/totalPagesSlice';
import commonStyles from 'components/common-styles.module.css';
import { useEffect, useState } from 'react';
import { useGlobal } from 'api/hooks/use-global';
import { symplifyNumber } from 'app/utils/numbers';
import styles from './styles.module.css';

const Description: React.FC = () => {
  const dispatch = useAppDispatch();
  const globalQuery = useGlobal()
  const currencySymbol = 'usd'
  const [totalMarketCap, setTotalMarketCap] = useState(0)
  const [capChange, setCapChange] = useState(0)

  useEffect(() => {
    if (globalQuery.data) {
      const { data } = globalQuery.data
      const pages = Math.ceil(data.active_cryptocurrencies / 100)
      dispatch(setTotalPages(pages))

      setTotalMarketCap(data.total_market_cap[currencySymbol])
      setCapChange(data.market_cap_change_percentage_24h_usd)
    }
  }, [dispatch, globalQuery.data])

  return (
    <div className={styles.parent}>
      <div className={styles.bigSize}>
        <div><span className={commonStyles.halalWord}>Halal</span> cryptocurencies</div>
        <div>a crowdsourced database</div>
      </div>
      <div className={styles.smallSize}>
        <p>Check cryptos for permissiveness and share your opinion.</p>
        <p>Let's create halal coins list for our Ummah!</p>
      </div>

      <div id="scroll-above-table" />
      <p className={styles.mediumSize}>Cryptocurrency Prices by Market Cap</p>
      <p className={styles.smallSize}>
        The global crypto market cap is
        <span className={commonStyles.increasingColor}> ${symplifyNumber(totalMarketCap)}</span>, a
        <span
          className={capChange >= 0 ? commonStyles.increasingColor : commonStyles.decreasingColor}
        >
          {` ${capChange.toLocaleString('en', { maximumFractionDigits: 2 })}`}%
        </span> change over the last day.
      </p>
    </div>
  );
}
export default Description;
