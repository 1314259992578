import React, { useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { noIndex } from 'app/utils/noIndex';
import styles from './styles.module.css';

interface Props {
  showOnEachSide: number
  maxPageSize: number;
}

const scrollTop = () => document.getElementById('scroll-above-table')?.scrollIntoView()

const Pagination: React.FC<Props> = ({ showOnEachSide, maxPageSize }: Props) => {
  if (maxPageSize < 1) {
    console.error('In pagination, maxPageSize is', maxPageSize);
    maxPageSize = 1
  }

  const [params] = useSearchParams({ page: '1' })
  const currentPage = Number(params.get('page') ?? 1)

  useEffect(() => {
    if (currentPage < 1) {
      return noIndex()
    }
  }, [currentPage])

  type ButtonAttributes = React.ButtonHTMLAttributes<HTMLAnchorElement> | { key?: string }
  const Button = (text: string, attributes: ButtonAttributes) => {
    const htmlAttributes = attributes as React.ButtonHTMLAttributes<HTMLElement>
    if (htmlAttributes.disabled) {
      const className = `${styles.button} ${styles.disabled} ${htmlAttributes.className}`
      return (
        <div key={text} className={className}>
          <span>{text}</span>
        </div>
      )
    }

    /* eslint-disable @typescript-eslint/indent */
    const page =
      text === 'Prev' ? currentPage - 1 :
      text === 'Next' ? currentPage + 1 : Number(text)
    /* eslint-enable @typescript-eslint/indent */
    const path = page === 1 ? '/' : `/?page=${page}`

    return (
      <Link onClick={scrollTop} key={text} to={path} className={styles.button} {...attributes}>
        <span>{text}</span>
      </Link>
    )
  }

  const isFirstPage = currentPage === 1
  const isLastPage = currentPage === maxPageSize

  const buttons = [Button('Prev', { disabled: isFirstPage })]
  if (!isFirstPage) {
    buttons.push(Button('1', {}))
  }

  let startPage: number;
  if (currentPage - 1 <= showOnEachSide) {
    startPage = 2
  } else {
    startPage = currentPage - showOnEachSide + 2
    buttons.push(Button('...', { disabled: true, key: '...prev' }))
  }

  let endPage: number;
  if (maxPageSize - currentPage <= showOnEachSide) {
    endPage = maxPageSize - 1
  } else {
    endPage = currentPage + showOnEachSide - 2
  }

  for (let i = startPage; i < currentPage; i++) {
    buttons.push(Button(i.toString(), {}))
  }

  buttons.push(Button(currentPage.toString(), { disabled: true, className: `${styles.button} ${styles.current}` }))

  for (let i = currentPage + 1; i <= endPage; i++) {
    buttons.push(Button(i.toString(), {}))
  }
  if (endPage + 1 !== maxPageSize) {
    buttons.push(Button('...', { disabled: true, key: '...next' }))
  }

  if (!isLastPage) {
    buttons.push(Button(maxPageSize.toString(), {}))
  }
  buttons.push(Button('Next', { disabled: isLastPage }))

  return <div className={styles.buttonsContainer}>{buttons}</div>
}
export default Pagination;
