interface Params extends Pick<React.HTMLAttributes<HTMLSpanElement>, 'className'> {
  text: string;
  pattern: string;
}

const TextMarker = ({ text, pattern, ...rest }: Params) => {
  const start = text.toLowerCase().indexOf(pattern.toLowerCase());
  if (start < 0) {
    return <span {...rest}>{text}</span>;
  }
  const offset = start + pattern.length;
  return (
    <span {...rest}>
      {text.substring(0, start)}
      <mark>{text.substring(start, offset)}</mark>
      {text.substring(offset)}
    </span>
  );
};

export default TextMarker
