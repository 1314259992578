import { QueryFunctionContext, QueryKey } from 'react-query';
import qs from 'qs';

type QueryParams = Record<string, string | number>;

function assertString(value: any): asserts value is string {
  if (typeof value !== 'string') throw new Error('Wrong');
}

function makeFullUrl(path: string, params: QueryParams | undefined) {
  if (path[0] === '/') throw new Error('Do not start with /');

  const queryParams = qs.stringify(params, { addQueryPrefix: true });

  return `https://api.coingecko.com/api/v3/${path}${queryParams}`;
}

export async function processCoingecko({ queryKey }: QueryFunctionContext<QueryKey>) {
  const [url, params] = queryKey;

  assertString(url);

  const resp = await fetch(makeFullUrl(url, params as QueryParams));
  // await new Promise((resolve) => setTimeout(resolve, 1000));

  if (!resp.ok) {
    throw new Error('Wrong');
  }

  return resp.json();
}

export async function processLocalPage({ queryKey }: QueryFunctionContext<QueryKey>) {
  const [url] = queryKey

  assertString(url)

  const resp = await fetch(url)

  if (!resp.ok) {
    return Promise.reject(new Error('Page not found 404'))
  }

  return resp.text()
}
