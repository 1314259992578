import { processCoingecko } from 'api';
import { useQuery } from 'react-query';

interface Global {
  data: {
    active_cryptocurrencies: number;
    // upcoming_icos: number;
    // ongoing_icos: number;
    // ended_icos: number;
    // markets: number;
    total_market_cap: { [symbol: string]: number };
    // total_volume: { [symbol: string]: number };
    // market_cap_percentage: { [symbol: string]: number };
    market_cap_change_percentage_24h_usd: number;
    // updated_at: number;
  }
}

export function useGlobal() {
  const resp = useQuery<Global>(['global'], processCoingecko, {
    onError(error) {
      console.error('error occured, please, check', error);
    },
  });
  return resp;
}
