import { useCurrencyInfo } from 'api/hooks/use-currency-info';
import { usePrices } from 'api/hooks/use-prices';
import { formatMoney } from 'app/utils/numbers';
import Category from 'components/Category/Category';
import { useParams } from 'react-router-dom';
import { getCoinData, HaramInfo } from 'app/utils/getCoinData/getCoinData';
// import HaramScreening from 'components/HaramScreening/HaramScreening';
import CoinDescription from 'components/CoinDescription/CoinDescription';
import { useDocumentTitle } from 'api/hooks/use-document-title';
import styles from './styles.module.css';

const UnverifiedData: HaramInfo = { risk: 'Unverified', haramConditions: {} }

const Cryptocurrency = () => {
  const currencyId = useParams().id!
  useDocumentTitle(currencyId)
  const infoQuery = useCurrencyInfo(currencyId);

  // Temporary disable comments
  // useEffect(() => {
  //   if (!infoQuery.data) return
  //   return initChat();
  // }, [infoQuery.data])

  const currentPricesQuery = usePrices({ ids: currencyId });

  const { data: infoData } = infoQuery;
  const upperSymbol = infoData?.symbol.toUpperCase()
  const currentPrice = currentPricesQuery.data?.[0]

  const community = infoData ? {
    Facebook: [`https://www.facebook.com/${infoData.links.facebook_username}`],
    Twitter: [`https://twitter.com/${infoData.links.twitter_screen_name}`],
    Reddit: [infoData.links.subreddit_url],
  } : {} as object

  const circulatedSupplyPercent = currentPrice?.total_supply
    ? (currentPrice.circulating_supply ?? 0) / currentPrice.total_supply * 100
    : null

  let coinData = UnverifiedData
  if (infoData) {
    const foundCoinData = getCoinData([infoData.id, infoData.name, infoData.symbol])
    if (foundCoinData) {
      coinData = foundCoinData
    }
  }

  return (
    <>
      <div className={styles.coinRank}>
        Rank #{infoData?.market_cap_rank}
      </div>
      <div className={styles.mainInfoLayout}>
        <div>
          <div className={styles.mainInfoContainer}>
            {infoData ? <img src={infoData.image.small} alt={`Small ${infoData.name}`} /> : null}
            <span className={styles.coinName}>{infoData?.name ?? `Loading info for ${currencyId}`}</span>
            <span className={styles.coinSymbol}>{upperSymbol}</span>
            <a href="https://binance.com" className={styles.buyButton}>Buy</a>
          </div>
          <div className={styles.labels}>
            <div>
              <Category defaultLabel="Website" urls={infoData?.links.homepage ?? []} />
            </div>
            <div>
              <Category defaultLabel="Source code" urls={infoData?.links.repos_url ?? []} />
            </div>
            <div>
              <Category defaultLabel="Community" urls={community} />
            </div>
            <div>
              <Category defaultLabel="Explorers" urls={infoData?.links.blockchain_site ?? []} />
            </div>
            <div>
              <Category defaultLabel="Categories" showAllWithoutRedirect urls={infoData?.categories.filter(Boolean) as string[] ?? []} />
            </div>
          </div>
        </div>
        <div className={styles.indicators}>
          <div>
            <div className={styles.indicatorTitle}>Price</div>
            <div className={styles.indicatorValue}>
              {formatMoney(currentPrice?.current_price ?? 0)}
            </div>
            <div className={styles.indicatorChange}>
              {formatMoney(currentPrice?.price_change_percentage_24h ?? 0)}%
            </div>
          </div>
          <div>
            <div className={styles.indicatorTitle}>Market Cap</div>
            <div className={styles.indicatorValue}>
              {formatMoney(currentPrice?.market_cap ?? 0)}
            </div>
            <div className={styles.indicatorChange}>
              {formatMoney(currentPrice?.market_cap_change_percentage_24h ?? 0)}%
            </div>
          </div>
          <div>
            <div className={styles.indicatorTitle}>Volume</div>
            <div className={styles.indicatorValue}>
              {formatMoney(currentPrice?.total_volume ?? 0)}
            </div>
            <div className={styles.indicatorChange}>-99.99%</div>
          </div>
          <div>
            <div className={styles.indicatorTitle}>Circulating Supply</div>
            <div className={styles.indicatorValue}>
              {currentPrice?.circulating_supply?.toLocaleString('en', { maximumFractionDigits: 0 }) ?? '(no data)'} {upperSymbol}
              <span style={{ marginLeft: '12px' }} title={circulatedSupplyPercent?.toLocaleString('en')}>{circulatedSupplyPercent ? `${Math.floor(circulatedSupplyPercent)}%` : null}</span>
            </div>
            <div className={styles.indicatorChange}>
              <div className={styles.progressBarContainer}>
                <div style={{ width: `${circulatedSupplyPercent}%` }} />
              </div>
            </div>
          </div>
          <div>
            <div className={styles.indicatorTitle}>Max Supply</div>
            <div className={styles.indicatorValue}>{currentPrice?.total_supply ? `${currentPrice.total_supply.toLocaleString('en', { maximumFractionDigits: 0 })} ${upperSymbol}` : '-'}</div>
            <div className={styles.indicatorChange} />
          </div>
        </div>
      </div>

      {/* {infoData
        ? <HaramScreening {...coinData} />
        : null} */}

      <CoinDescription
        name={infoData?.name ?? currencyId}
        currencyId={currencyId}
        internalDescription={infoData?.description.en}
        risk={coinData.risk}
      />

      <div className={styles.chatContainer}>
        <div id="remark42" />
        {infoQuery.data
          ? <div id="commento" data-page-id={infoQuery.data.symbol} />
          : null}
      </div>
    </>
  )
}

export default Cryptocurrency
