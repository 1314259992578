import { processCoingecko } from 'api';
import { useQuery } from 'react-query';

interface Pagination {
  perPage: number;
  pageNumber: number;
}

export interface Coin {
  id: string; // "bitcoin"
  name: string; // "Bitcoin"
  symbol: string; // "btc"
  circulating_supply: number | null;
  current_price: number | null;
  ath: number; // maximum price
  market_cap: number;
  market_cap_change_percentage_24h: number;
  total_supply: number | null;
  total_volume: number;
  image: string; // "https://assets.coingecko.com/coins/images/1/large/bitcoin.png?1547033579"

  // price_change_percentage_(1h,24h,7d)_in_currency
  // can be null when the coin is listed recently
  price_change_percentage_7d_in_currency: number | null;
  price_change_percentage_24h: number | null;
  sparkline_in_7d: { price: number[] };
}

export function usePrices(options?: { pagination?: Pagination, ids?: string }) {
  const { perPage, pageNumber } = options?.pagination ?? { perPage: 100, pageNumber: 1 };
  const ids = options?.ids

  const params = {
    vs_currency: 'usd',
    ids,
    per_page: perPage,
    page: pageNumber,
    sparkline: 'true',
    price_change_percentage: '7d',
  };

  const resp = useQuery<Coin[]>(['coins/markets', params], processCoingecko, {
    onError(error) {
      console.error('error occured, please, check', error);
    },
  });
  return resp;
}
