export function reset(ctx: CanvasRenderingContext2D) {
  ctx.clearRect(0, 0, 1000, 1000)
  ctx.resetTransform()
  // ctx.restore()
}

const RISING_COLOR = '#3ACC8A'
const DECLINING_COLOR = '#DF5F67'
const NEUTRAL_COLOR = 'lightgray'

function chooseColor(prices: number[]) {
  const firstPrice = prices[0]
  const lastPrice = prices[prices.length - 1]

  const diff = Math.abs((firstPrice - lastPrice))
  const diffInPercent = diff / firstPrice // if went from 100 to 105 or 95 => 0.05 (5%)
  if (diffInPercent <= 0.01) return NEUTRAL_COLOR;

  return firstPrice < lastPrice ? RISING_COLOR : DECLINING_COLOR
}

function getMinMax(prices: number[]) {
  let min = prices[0]
  let max = prices[0]
  for (const price of prices) {
    if (max < price) max = price
    else if (min > price) min = price
  }
  return { min, max }
}

function configureContext(ctx: CanvasRenderingContext2D, prices: number[]) {
  ctx.lineWidth = 3
  ctx.strokeStyle = chooseColor(prices)
}

export function drawChart(canvas: HTMLCanvasElement, prices: number[]) {
  const { min, max } = getMinMax(prices)

  const ctx = canvas.getContext('2d')!
  configureContext(ctx, prices)

  const { width, height } = canvas
  const step = width / prices.length

  let x = 0
  ctx.beginPath();
  for (const p of prices) {
    const y = height - (p - min) / (max - min) * height
    ctx.lineTo(x, y)
    x += step
  }
  ctx.stroke()
}
