import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';

export interface TotalPagesState {
  value: number;
}

const initialState = { value: 0 } as TotalPagesState

const totalPagesSlice = createSlice({
  name: 'totalPages',
  initialState,
  reducers: {
    setTotalPages(state, action: PayloadAction<number>) {
      state.value = action.payload
    },
  },
})

export const selectPages = (state: RootState) => state.totalPages.value

export const { setTotalPages } = totalPagesSlice.actions
export default totalPagesSlice.reducer
