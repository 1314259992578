// https://api.coingecko.com/api/v3/search?query=bit

import { processCoingecko } from 'api';
import { useQuery } from 'react-query';

export interface SearchResult {
  coins: Coin[];
  exchanges: ExchangesEntity[];
  nfts: NftsEntity[];
}
interface NftsEntity {
  id?: string | null;
  name: string;
  symbol: string;
  thumb: string;
}
interface Coin extends NftsEntity {
  id: string;
  market_cap_rank: number;
}
interface ExchangesEntity extends NftsEntity {
  id: string;
  market_type: string;
}

export function useSearch(query: string) {
  const params = { query }

  const resp = useQuery<SearchResult>(['search', params], processCoingecko, {
    onError(error) {
      console.error('error occured, please, check', error);
    },
    enabled: query.length !== 0,
  });
  return resp;
}
