import coinsData from './coins-data.json';

type CoinName = keyof typeof coinsData

export const Conditions = {
  legitimacy: 'Legitimacy',
  project: 'Project',
  token: 'Token',
  staking: 'Staking',
}
type TypeOfConditionKeys = keyof typeof Conditions
export type Status = 'halal' | 'haram' | undefined
export type Risk = 'Low' | 'Medium' | 'High' | 'Unverified'

export interface HaramInfo {
  haramConditions: {
    [Prop in TypeOfConditionKeys]?: Status
  };
  risk: Risk;
}

export const ConditionKeys = Object.keys(Conditions) as TypeOfConditionKeys[]

function coinHash(coin: string) {
  return coin.replace(/[- ]/g, '').toLowerCase()
}

export function getCoinData(coins: string[]): HaramInfo | null {
  const foundCoins: string[] = [];
  const uniqueCoins = new Set(coins.map(coinHash))
  for (const coinText of uniqueCoins) {
    const lower = coinHash(coinText)
    if (lower in coinsData) {
      foundCoins.push(lower)
    }
  }
  if (foundCoins.length === 0) return null
  if (foundCoins.length > 1) console.warn('Duplicate coins:', foundCoins)
  return coinsData[foundCoins[0] as CoinName] as any
}
