import commonStyles from 'components/common-styles.module.css';
import Search from 'components/Search/Search';
import styles from './styles.module.css';

export default function Header() {
  return (
    <header>
      <aside className={styles.title}>
        <a href="/">
          <span className={commonStyles.halalWord}>Halal</span>CoinList
        </a>
      </aside>
      <aside>
        <div className={styles.menuItems}>
          <Search />
          <a href="https://halalcoinlist.notion.site/Methodology-11a18306434f4ebab08935a3c5c47025">Methodology</a>
          <a href="https://halalcoinlist.notion.site/Contact-us-523146ff835c4d38b61c04f352dd42f0">About us</a>
          <a target="_blank" rel="noreferrer" href="https://t.me/hclchat">Telegram</a>
        </div>
      </aside>
    </header>
  );
}
